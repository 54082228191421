import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    loading: true,
    errored: false,
    population: [],
    stock: []
  },
  mutations: {
    set_loading(state, loading) {
      state.loading = loading
    },

    set_errored(state, errored) {
      state.errored = errored
    },
    set_population(state, population) {
      state.population = population
    },
    set_stock(state, stock) {
      state.stock= stock
    }
  },
  actions: {
      getPopulation({ commit }) {
        commit('set_loading', true)
        commit('set_errored', false)
        axios.get("/api/population")
          .then(response => {
            console.log(response)
            commit('set_population', response.data.data)
          })
          .catch(error => {
            commit('set_errored', true)
          })
          .finally(() => {
            commit('set_loading', false)
          })
    },
    getStock({ commit }) {
        commit('set_loading', true)
        commit('set_errored', false)
        axios.get("/api/stock")
          .then(response => {
            console.log(response)
            commit('set_stock', response.data.data)
          })
          .catch(error => {
            commit('set_errored', true)
          })
          .finally(() => {
            commit('set_loading', false)
          })
    }
  },
  getters: {
    population: (state) => {
      return state.population
    },
    stock: (state) => {
      return state.stock
    }
  },
  modules: {
  }
})
