
// @ is an alias to /src
import Tech from "@/components/Tech.vue";

export default {
  name: "Home",
  components: {
    Tech,
  },
};
