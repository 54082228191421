import { createApp } from 'vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ScriptX from 'vue-scriptx'


import App from './App.vue'
import router from './router'
import store from './store'
const app = createApp(App)

// 图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// createApp(App).use(store).use(router).mount('#app')

app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(ScriptX)
app.mount('#app')
