
import Navi from "@/components/Navi.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "navi",
  components: {
    Navi,
    Footer,
  },
};
