<template>
  <div class="tech">
    <h2>
     该部分内容已迁移至<a href="https://mephisto.cc">https://mephisto.cc</a>
    </h2>
    <el-scrollbar>
        <h1 v-for="(item,index) in static_links" :key="item.index">
          <el-icon><Link /></el-icon>
          <a :href="static_base + item.file_name" >{{index+1}}. {{item.title}}</a >
        </h1>
  </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "tech",
  props: {
    msg: String,
  },
  data(){
    return {
      static_base: "https://mephisto.cc/tech/",
      static_links: [
        {file_name:"alacritty", title:"alacritty 终端使用"},
        {file_name:"raspberrypi", title:"搭建树莓派无线路由器"},
        {file_name:"caddyfile", title:"Caddy2 Vue history mode配置"},
        {file_name:"notify-send", title:"Ubuntu notify-send 定时通知"},
        {file_name:"lvs", title:"lvs部署"},
        {file_name:"ubuntu-ldap-client", title:"ubuntu12.04 ldap_client客户端设置"},
        {file_name:"webdav", title:"Nginx Webdav Joplin实用配置"},
        {file_name:"gohttpserver", title:"Linux和iPhone互传文件"},
        {file_name:"zfs", title:"ZFS snapshot简单测试"},
      ]
    }
  }
};
</script>

<style scoped>
a:hover {color:#FF00FF;}  /* 鼠标移动到链接上 */
a {
  text-decoration:none;
  padding: 4px;
}

.tech {
  max-width: 800px;
  margin:0 auto;
}

h1 {
  padding-left: 16px;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 50px;
  margin: 10px;
  text-align: left;
  border-radius: 4px;
  background:rgb(197, 195, 195);
}
</style>
