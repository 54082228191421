import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import Home from '../views/Home.vue'
import Tech from '../views/Tech.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/tech',
    name: 'Tech',
    component: Tech
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "stock" */ "../views/Stock.vue"),
  },
  {
    path: "/create-stock",
    name: "ceate-stock",
    component: () =>
      import(/* webpackChunkName: "create_stock" */ "../views/CreateStock.vue"),
  },

  {
    path: "/create-population",
    name: "ceate-population",
    component: () =>
      import(
        /* webpackChunkName: "create_population" */ "../views/CreatePopulation.vue"
      ),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
